'use strict';
/**
 * Takes the current password value and tests each constraint against the appropriate regex expression
 * If regex criteria is met, it will add isValid
 * @param {string} passwordValue - current value in the password field
 * @param {string} constraintField - selector of the constraint
 * @param {string} regexPattern - pattern that will be used to test against
 * @return {boolean} - true if constraint is met, false otherwise
 */
function showPasswordConstraint(passwordValue, constraintField, regexPattern) {
    // Regex Pattern will not exist if we do not enforce mixed case, min number or min special character requirements
    var constraintMet = true;
    if (regexPattern) {
        var $constraintField = $(constraintField);
        var regex = new RegExp(regexPattern);
        if (regex.test(passwordValue)) {
            $constraintField.addClass('isValid');
        } else {
            $constraintField.removeClass('isValid');
            constraintMet = false;
        }
    }
    return constraintMet;
}

/**
 * Takes the current password value and tests each constraint against the appropriate regex expression
 * If regex criteria is met, it will add isValid
 * @param {string} $passwordField - current value in the password field
 * @return {boolean} - true if constraint is met, false otherwise
 */
function checkPasswordValidity($passwordField) {
    var isValid = true;
    if ($passwordField) {
        var passwordValue = $passwordField.val();
        var $constraintField = $passwordField.parents('.form-group').find('.constraintRegex');
        var $invalidPasswordField = $passwordField.parents('.form-group').find('.invalid-password');
        var hideConstraints = $passwordField.parents('form').data('hide-constraints');
        var allowEmpty = $constraintField.data('allow-empty');
        var constraintsMet;

        if (!hideConstraints) {
            var constraintsJSON = $constraintField.data('pass-regex');
            var minCharConstraint = showPasswordConstraint(passwordValue, '.min-char-constraint', constraintsJSON.minLengthRegex);
            var lowerCaseConstraint = showPasswordConstraint(passwordValue, '.lower-case-constraint', constraintsJSON.lowerCaseRegex);
            var upperCaseConstraint = showPasswordConstraint(passwordValue, '.upper-case-constraint', constraintsJSON.upperCaseRegex);
            var minNumberConstraint = showPasswordConstraint(passwordValue, '.min-number-constraint', constraintsJSON.numberRegex);
            var minSpecialCharConstraint = showPasswordConstraint(passwordValue, '.min-specialChar-constraint', constraintsJSON.specialCharRegex);

            if ((minCharConstraint && lowerCaseConstraint && upperCaseConstraint && minNumberConstraint && minSpecialCharConstraint) || (allowEmpty && !passwordValue.length)) {
                constraintsMet = true;
            }
        }

        if (constraintsMet || hideConstraints) {
            $invalidPasswordField.addClass('d-none');
            $passwordField.removeClass('is-invalid');
            $passwordField.parent().removeClass('is-invalid');
        } else {
            // Password field is only invalid if focus is lost before input is valid
            isValid = false;
        }
    }
    return isValid;
}

module.exports = {
    init: function () {
        $('body').off('keyup change', '.js-password-checker').on('keyup change', '.js-password-checker', function (e) {
            e.preventDefault();
            var $passwordField = $(this);
            checkPasswordValidity($passwordField);
        });
    },
    checkPasswordValidity: checkPasswordValidity
};
